import React from 'react'
import BaseLayout from '../../layouts/BaseLayout';

export default function elections() {
    return (
        <BaseLayout>
            
        </BaseLayout>
    )
}
